import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

import { doSignOut } from '../api/firebase/sign_out';
import { useConnect } from '../api/metamask/use_connect';
import { useFirebaseUser } from '../contexts/firebase_user.context';
import { useMetamask } from '../contexts/metamask.context';
import { Spinner } from './components/Spinner';

export const UserMenu = () => {
  const user = useFirebaseUser();
  const { isConnected, publicAddress } = useMetamask();
  const connect = useConnect();

  // If not fully connected
  if (!isConnected || !user) {
    return (
      <div className='flex items-center justify-center '>
        <div className='hidden md:inline-block relative z-10 text-left'>
          <button
            className={`inline-flex items-center bg-black hover:bg-not-black justify-center w-56 px-4 py-2 text-small transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 text-white active:text-gray-800 rounded-xl`}
            // disabled={isDisabled}
            onClick={() => connect.mutate()}>
            {connect.isLoading && <Spinner />}
            <img
              className='h-5 w-5 mr-2'
              src='/wallet-white.svg'
              alt='Wallet icon'
            />
            Connect wallet
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='flex items-center justify-center '>
      <div className='relative z-10 inline-block text-left'>
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className='inline-flex items-center justify-center space-x-2 w-full px-4 py-2 text-small transition duration-150 ease-in-out focus:outline-none focus:border-sky-300 focus:shadow-outline-sky active:bg-gray-50 active:text-gray-800'>
                <span className='text-sm font-bold'>
                  {`${publicAddress.slice(0, 6)}...${publicAddress.slice(
                    publicAddress.length - 4,
                  )}`}
                </span>
                <img
                  className='h-8 p-0.5 w-8 mr-2 bg-black rounded-full'
                  src='/wallet-white.svg'
                  alt='Wallet icon'
                />
                {/* <img
                  className='h-8 w-8 object-cover rounded-full'
                  src={user?.photoURL || '/default-avatar.svg'}
                  alt="User's avatar"
                /> */}
              </Menu.Button>

              <Transition
                show={open}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'>
                <Menu.Items
                  static
                  className='absolute z-10 right-0 w-56 mt-2 origin-top-right bg-not-gray border divide-y divide-gray-100 outline-none'>
                  <div className='md:hidden py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/book'
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } flex justify-between w-full px-4 py-2 text-small text-left`}>
                          Book
                        </Link>
                      )}
                    </Menu.Item>
                  </div>

                  <div className='md:hidden py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/my-pages'
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } flex justify-between w-full px-4 py-2 text-small text-left`}>
                          My pages
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                  <div className='md:hidden py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/available'
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } flex justify-between w-full px-4 py-2 text-small  text-left`}>
                          Next available page
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                  <div className='py-1'>
                    {!!user ? (
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={`${
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700'
                            } flex justify-between w-full px-4 py-2 text-small `}
                            onClick={() => doSignOut()}>
                            Disconnect
                          </div>
                        )}
                      </Menu.Item>
                    ) : null}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};

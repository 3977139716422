import { ethers } from 'ethers';
import { doc, getDoc } from 'firebase/firestore';
import { QueryKey, useQuery } from 'react-query';
import { getCollection } from '../../utils/get_collection';
import { unpackSnapshot } from '../../utils/unpack_snapshot';
import nft from '../contracts/LudoBookNFT.json';
import FirebaseClient from '../firebase/firebase_client';

const { abi } = nft;

export const ownerOf = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [, { address, number }] = queryKey as any[];

  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, abi, provider);

    try {
      const data = await contract.ownerOf(number);
      // console.log('ownerOf', data);
      return data;
    } catch (err: any) {
      console.error('Error: ', err);
      throw new Error(`ownerOf Error: ${err}`);
    }
  } else {
    console.warn('Metamask not found. Trying firestore');
    const slug = getCollection();
    const snapshot = await getDoc(
      doc(FirebaseClient.db, `/contracts/${slug}/pages/${number}`),
    );
    return unpackSnapshot({ snapshot })?.ownedBy;
  }
};

export const useOwnerOf = ({
  number,
  address,
}: {
  number: number | string | undefined;
  address: string | undefined;
}) => {
  return useQuery(['ownerOf', { address, number }], ownerOf, {
    enabled: !!number && !!address,
  });
};

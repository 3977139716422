import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { UserMenu } from '../views/UserMenu';

const NavLink = ({ to, text }) => (
  <Link
    to={to}
    className='text-medium ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent'>
    {text}
  </Link>
);

const NavA = ({ to, text }) => (
  <a
    href={to}
    className='ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-medium'>
    {text}
  </a>
);

export const DesktopNavBar = () => {
  return (
    // <nav className='bg-white shadow'>
    <nav className='hidden md:block fixed top-2 px-8 z-30 w-full bg-not-gray'>
      <div className='border-2 border-black'>
        <div className='px-2 sm:px-6 lg:px-8'>
          <div className='relative flex justify-between h-16'>
            <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
              <div className='flex-shrink-0 flex items-center'>
                <Link to='/'>
                  <img
                    className='block h-10 w-auto'
                    src='/ludo.png'
                    alt='Flourishing app logo blac letter f a green background'
                  />
                </Link>
              </div>
              {/* desktop layout */}
              <div className='hidden sm:ml-6 sm:flex'>
                <NavLink to='/book' text='Book' />
                <NavLink to='/my-pages' text='My Pages' />
                <NavLink to='/available' text='Free page' />
                <NavA to='https://ludocollective.com' text='About' />
              </div>
            </div>
            <div className='self-center'>
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

import { ethers } from 'ethers';
import { useMutation } from 'react-query';
import { editPage } from '../../api/metamask/edit_page';
import { useContract } from '../../contexts/contract.context';
import { useMetamask } from '../../contexts/metamask.context';
import { Spinner } from '../components/Spinner';

export const PageOwnerForm = ({ page, text, ...props }) => {
  // console.log('PageOwnerForm', page);
  const { isConnected, publicAddress } = useMetamask();
  const { address: contractAddress } = useContract();

  console.log('text', text);

  // MUTATIONS
  const mSaveChanges = useMutation(
    async (text: string) => {
      if (!publicAddress) throw new Error('Metamask user not found');

      const pageNumber = page?.number;
      // console.log('EditPageForm pageNumber', pageNumber);

      const result = await editPage({
        pageNumber,
        text,
        address: contractAddress,
      });
      // console.log('editPage Result', result);

      return { result };
    },
    {
      onSuccess: (data, action) => {},
    },
  );

  return (
    <div className='p-8'>
      <header>
        <h2 className='text-large-semibold'>You own this page!</h2>
        {/* <h1>Current Value: {value}</h1> */}
      </header>
      <div className='mt-20'>
        {mSaveChanges.isLoading ? (
          <button
            className='w-full p-4 border-2 border-black bg-black text-white'
            onClick={() => mSaveChanges.mutate(text)}>
            <Spinner />
            <span>Saving... may take ten minutes or more</span>
          </button>
        ) : (
          <button
            className='w-full p-4 border-2 border-black hover:bg-black hover:text-white'
            onClick={() => mSaveChanges.mutate(text)}>
            Save changes to the blockchain
          </button>
        )}
      </div>
    </div>
  );
};

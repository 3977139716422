import nft from './contracts/LudoBookNFT.json';

const contractAbi = nft.abi;

// let contractAddress;
// if (process.env.NODE_ENV.toLowerCase() !== 'production') {
//   contractAddress = '0x9aD3F7EeeBFfC9b2A94873f025d91e2993E61b01';
// } else {
//   contractAddress = '0x9aD3F7EeeBFfC9b2A94873f025d91e2993E61b01';
// }

export { contractAbi };

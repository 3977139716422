import MetaMaskOnboarding from '@metamask/onboarding';
import { ethers } from 'ethers';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import FirebaseClient from '../../api/firebase/firebase_client';
import { signInWithMetamask } from '../../api/firebase/sign_in_with_metamask';

const ONBOARD_TEXT = 'Click here to install MetaMask!';
const CONNECT_TEXT = 'Connect';
const CONNECTED_TEXT = 'Connected';

/**
 * Custom hook for connecting a user's wallet and then signing in (or creating)
 * a Firebase Auth account.
 */
export const useConnect = () => {
  const [nonce, setNonce] = useState('');
  const [accounts, setAccounts] = useState([]);
  const onboarding = useRef<MetaMaskOnboarding>();

  const handleConnect = useMutation(async () => {
    // if (!accounts) return;
    // account: string[]
    // console.log(accounts);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    // If we have a metamask publicAddress, we send that to our servers
    // User then needs to sign the nonce
    const nonceResponse: any = await httpsCallable(
      FirebaseClient.functions,
      'signInWithMetamask',
    )({
      publicAddress: accounts[0],
      method: 'get-nonce',
    });
    const { nonce } = nonceResponse?.data;
    console.log('nonce', nonce);
    setNonce(nonceResponse.data.nonce);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const message = `Please sign this message to log in. \n nonce=${nonce}`;
    const signature = await signer.signMessage(message);
    console.log('signature', signature);

    const request = {
      publicAddress: accounts[0],
      method: 'verify-nonce',
      signature,
    };
    console.log('request', request);

    const sigResponse: any = await httpsCallable(
      FirebaseClient.functions,
      'signInWithMetamask',
    )(request);
    console.log(sigResponse);

    // Received our Custom Token that we can use to sign in/up
    signInWithMetamask(sigResponse.data);
  });

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  // useEffect(() => {
  //   if (MetaMaskOnboarding.isMetaMaskInstalled()) {
  //     if (accounts.length > 0) {
  //       setButtonText(CONNECTED_TEXT);
  //       setDisabled(true);
  //       onboarding.current?.stopOnboarding();
  //     } else {
  //       setButtonText(CONNECT_TEXT);
  //       setDisabled(false);
  //     }
  //   }
  // }, [accounts]);

  useEffect(() => {
    function handleNewAccounts(newAccounts: any) {
      setAccounts(newAccounts);
      console.log('newAccounts', newAccounts);

      //Need to call our servers to get a nonce to sign, then send that back
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      // This is the method that we intercept to hit our servers first to get the nonce? But, will need the public address first. So. This is really a two part operation?
      // window.ethereum
      //   .request({ method: "eth_requestAccounts" })
      //   .then(handleNewAccounts);
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        // FIXME: off is not a function
        // window.ethereum?.off("accountsChanged", handleNewAccounts);
      };
    }
  }, []);

  return handleConnect;
};

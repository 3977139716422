import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from './api/react-query';
import { ContractProvider } from './contexts/contract.context';
import { FirebaseAuthProvider } from './contexts/firebase_user.context';
import { MetamaskProvider } from './contexts/metamask.context';
import { DesktopNavBar } from './nav/DesktopNavBar';
import { MobileNavBar } from './nav/MobileNavBar';
import { Router } from './routes/Router';

// Hack to make typescript not yell about window.ethereum
declare global {
  interface Window {
    ethereum: any;
  }
}

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ContractProvider>
          <FirebaseAuthProvider>
            <MetamaskProvider>
              <DesktopNavBar />
              <MobileNavBar />

              <div className='pt-20' />
              <Router />
            </MetamaskProvider>
          </FirebaseAuthProvider>
        </ContractProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;

import { ethers } from 'ethers';
import { contractAbi } from '../config';

// FIXME:
const ABI = contractAbi;
// const address = contractAddress;

export async function editPage({
  pageNumber,
  text,
  address,
}: {
  pageNumber: string | number;
  text: string;
  address: string;
}) {
  if (typeof window.ethereum === 'undefined') {
    alert('Metamask not found. Please install');
    throw new Error('Metamask not found');
  }

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // console.log("Bid Provider: ", provider);
  const signer = provider.getSigner();
  // console.log("Bid signer", signer);
  const auctionContract = new ethers.Contract(address, ABI, signer);
  // console.log('Found contract', address, signer);

  try {
    console.log('Starting PageEdit Transaction', pageNumber, text);

    const transaction = await auctionContract.write(pageNumber, text);
    console.log('Transaction', transaction);

    const result = await transaction.wait();
    console.log('EditPage Transaction Result', result);
    return result;
  } catch (error) {
    console.error('EditPage Error:', error);
  }
}

export const PageOwnedForm = ({ page, text, ...props }) => {
  // console.log('PageOwnerForm', page);
  // const { isConnected, publicAddress } = useMetamask();

  console.log('text', text);

  return (
    <div className='p-8'>
      <header className='flex flex-wrap items-baseline'>
        <h2 className='text-2xl leading-160 font-semibold mr-8'>
          This page has an owner
        </h2>
        <a
          className='text-base leading-160 block underline'
          href={props.etherscan}
          target='_blank'
          rel='noopener noreferrer'>
          View on Etherscan
        </a>

        {/* <h1>Current Value: {value}</h1> */}
      </header>
    </div>
  );
};

import { doc, onSnapshot } from 'firebase/firestore';
import { createContext, useContext } from 'react';
import { useEffect, useState } from 'react';

import FirebaseClient from '../api/firebase/firebase_client';
import { unpackSnapshot } from '../utils/unpack_snapshot';

interface Value {
  address: string;
}

export const ContractContext = createContext<Value>({
  address: '',
});

/**
 *  Context for providing currently connected wallet and network information.
 *
 */
export const ContractProvider = ({ children }: { children: any }) => {
  const [address, setAddress] = useState('');

  const value = { address };

  /**
   * Contracts Listener
   */
  useEffect(() => {
    const book =
      window.location.host.includes('book-one-test') ||
      window.location.host.includes('localhost')
        ? 'book-one-test'
        : 'book-one';
    return onSnapshot(
      doc(FirebaseClient.db, `contracts/${book}`),
      (snapshot: any) => {
        const doc = unpackSnapshot({ snapshot });
        console.log('Found contract address', doc.contract.address);
        setAddress(doc.contract.address);
      },
    );
  }, []);

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};

export const useContract = () => useContext(ContractContext);

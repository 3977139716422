import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';
import { useNextAvailable } from '../api/firebase/use_next_available';

export const NextAvailable = () => {
  const { data, isLoading } = useNextAvailable();
  console.log('data', data);

  const history = useHistory();

  useEffect(() => {
    if (!!data) {
      history.push(`/pages/${data?.id}`);
    }
  }, [data, history]);

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center'>Loading...</div>
    );
  }

  if (data.length === 0) {
    return (
      <div className='grid grid-cols-1 place-items-center'>
        No more available pages
      </div>
    );
  }

  return null;
};

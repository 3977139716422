import { ethers } from 'ethers';
import { QueryKey, useQuery } from 'react-query';
import nft from '../contracts/LudoBookNFT.json';

const { abi } = nft;

export const call = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [, { address, number }] = queryKey as any[];

  if (typeof window.ethereum === 'undefined') {
    if (number == 1 || number == 100) return 1;
    return 0;
  }

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(address, abi, provider);

  try {
    const data = await contract.getInitialPrice(number);
    // console.log('Initial Price', data);

    return data;
  } catch (err) {
    console.error('Error: ', err);
    throw new Error(`useInitialPrice Error: ${err}`);
  }
};

export const useInitialPrice = ({
  number,
  address,
}: {
  number: number | string | undefined;
  address: string | undefined;
}) => {
  return useQuery(['initialPrice', { number }], call, {
    enabled: !!number && !!address,
  });
};

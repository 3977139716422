import { ethers } from 'ethers';
import { doc, getDoc } from 'firebase/firestore';
import { QueryKey, useQuery } from 'react-query';
import { getCollection } from '../../utils/get_collection';
import { unpackSnapshot } from '../../utils/unpack_snapshot';
import nft from '../contracts/LudoBookNFT.json';
import FirebaseClient from '../firebase/firebase_client';

const { abi } = nft;

export const call = async ({ queryKey }: { queryKey: QueryKey }) => {
  if (typeof window.ethereum !== 'undefined') {
    const [, { address }] = queryKey as any[];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, abi, provider);
    // console.log('contract', contract);

    try {
      const data = await contract.pagesWritten();
      // console.log('Page', data);
      return data;
    } catch (err) {
      console.error('Error: ', err);
      throw new Error(`Error: ${err}`);
    }
  } else {
    console.warn('Metamask not found. Trying firestore');
    const slug = getCollection();
    const snapshot = await getDoc(doc(FirebaseClient.db, `/contracts/${slug}`));
    return unpackSnapshot({ snapshot })?.pagesWritten || 0;
  }
};

export const usePagesWritten = ({
  address,
}: {
  address: string | undefined;
}) => {
  return useQuery(['pages-written', {}], call, { enabled: !!address });
};

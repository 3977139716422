import { ethers } from 'ethers';
import { contractAbi } from '../config';

const ABI = contractAbi;
// const address = contractAddress;

export async function buyPage({
  pageNumber,
  price,
  text,
  address,
}: {
  pageNumber: string;
  price: string;
  text: string;
  address: string;
}) {
  if (typeof window.ethereum === 'undefined') {
    alert('Metamask not found. Please install');
    throw new Error('Metamask not found');
  }
  console.log('Buy page', pageNumber, price, text, address);

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // console.log("Bid Provider: ", provider);
  const signer = provider.getSigner();
  // console.log("Bid signer", signer);
  const auctionContract = new ethers.Contract(address, ABI, signer);
  // console.log('Found contract', address, signer);
  const value = ethers.utils.parseEther(price);

  try {
    const txOptions = { value };
    // console.log("bid value", txOptions.value);

    const transaction = await auctionContract.buyPage(
      pageNumber,
      text,
      txOptions,
    );
    // console.log('Transaction', transaction);

    const result = await transaction.wait();
    console.log('BuyPage Transaction Result', result);
    return result;
  } catch (error) {
    console.error('Buy Page Error:', error);
  }
}

export const Versions = () => {
  return (
    <div className='mx-auto max-w-7xl'>
      <header>
        <h1>Book I - Versions</h1>
      </header>
      <main>
        <ul>
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>{' '}
          <li>
            Version <span className='underline text-blue-500'>link to pdf</span>
          </li>
        </ul>
        <div className='py-20' />
      </main>
    </div>
  );
};

import { ethers } from 'ethers';
import { doc, getDoc } from 'firebase/firestore';
import { QueryKey, useQuery } from 'react-query';
import { getCollection } from '../../utils/get_collection';
import { unpackSnapshot } from '../../utils/unpack_snapshot';
import nft from '../contracts/LudoBookNFT.json';
import FirebaseClient from '../firebase/firebase_client';

const { abi } = nft;

export const page = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [, { address, number }] = queryKey as any[];

  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, abi, provider);
    // console.log('contract', contract);

    try {
      const data = await contract.pages(number);
      // console.log('Page', data);

      return {
        number: data[0],
        text: data[1],
        rating: data[2].toString(),
        created: data[3],
        version: data[4],
        value: 0, //TODO:
      };
    } catch (err) {
      console.error('Error: ', err);
      throw new Error(`Error: ${err}`);
    }
  } else {
    console.warn('Metamask not found. Trying firestore');
    const slug = getCollection();
    const snapshot = await getDoc(
      doc(FirebaseClient.db, `/contracts/${slug}/pages/${number}`),
    );
    console.log('snapshot', snapshot);

    return unpackSnapshot({ snapshot });
  }
};

export const usePage = ({
  number,
  address,
}: {
  number: number | string | undefined;
  address: string | undefined;
}) => {
  return useQuery(['pages', { address, number }], page, {
    enabled: !!number && !!address,
  });
};

import { useMyPages } from '../api/firebase/use_my_pages';
import { useMetamask } from '../contexts/metamask.context';
import { Card } from './Pages';

export const MyPages = () => {
  const { publicAddress } = useMetamask();
  const { data, isLoading } = useMyPages({ address: publicAddress });

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center'>Loading...</div>
    );
  }

  return (
    <main className='px-5'>
      <ul className='flex flex-wrap justify-between'>
        {data?.map((page) => {
          return <Card page={page} key={page.id} />;
        })}
      </ul>
    </main>
  );
};

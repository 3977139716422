import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useOwnerOf } from '../api/metamask/use_owner_of';
import { usePage } from '../api/metamask/use_page';
import { BuyPageForm } from './forms/BuyPageForm';

import { Field, Form, Formik } from 'formik';
import { useMetamask } from '../contexts/metamask.context';
import { PageOwnerForm } from './forms/PageOwnerForm';
import { useEffect, useState } from 'react';
import { useInitialPrice } from '../api/metamask/use_initial_price';
import { ethers } from 'ethers';
import { usePagesWritten } from '../api/metamask/use_page_written';
import { PageOwnedForm } from './forms/PageOwnedForm';
import { ScrollToTop } from '../nav/ScrollToTop';
import { useContract } from '../contexts/contract.context';

const BOOK_LENGTH = 100;

export const Page = () => {
  const params = useParams<{ number: string }>();
  const pageNumber = parseInt(params.number);

  const [text, setText] = useState<string | undefined>(undefined);

  const { publicAddress } = useMetamask();
  const { address: contractAddress } = useContract();
  const { data, isLoading } = usePage({
    number: pageNumber,
    address: contractAddress,
  });
  const { data: pagesWritten } = usePagesWritten({ address: contractAddress });

  const version = data?.version || '0';
  const value = data?.value || 0; //TODO: this will be a BigNumber

  const { data: ownedBy } = useOwnerOf({
    number: pageNumber,
    address: contractAddress,
  });

  console.log('Page Data', isLoading, data, ownedBy, contractAddress);

  const owner = ownedBy
    ? `${ownedBy.slice(0, 6)}...${ownedBy.slice(ownedBy.length - 4)}`
    : '0x0000...0000';
  // const page = getPage(params.number);

  const { data: price } = useInitialPrice({
    number: data?.pageNumber || 1,
    address: contractAddress,
  });
  const formattedPrice = ethers.utils.formatEther(price || 0);

  const etherscan = `https://ropsten.etherscan.io/token/${contractAddress}?a=${pageNumber}`;

  useEffect(() => {
    if (!data) return;
    setText(data?.text);
  }, [pageNumber, data]);

  // TODO: if owned or available, drop into edit mode, otherwise we are in view mode
  let mode = 'view';
  if (publicAddress && (ownedBy === publicAddress || !data?.created)) {
    mode = 'edit';
  }

  let state = 'unavailable';
  if (!data?.created) {
    state = 'available';
  } else if (ownedBy === publicAddress) {
    state = 'owned';
  }

  let banner;
  if (state === 'owned') {
    banner = 'You own this page';
  } else if (state === 'unavailable') {
    banner = `Page owned by ${ownedBy}`;
  }

  return (
    <div className='relateive min-h-screen -mt-4 bg-not-gray'>
      <ScrollToTop />
      <main className='relative mx-auto px-8 grid grid-cols-1 md:grid-cols-12 '>
        {/*  */}
        <section className='relative col-span-7 p-8 border-2 border-black overflow-hidden'>
          {/* Banner bar */}
          {!!banner && (
            <p className='px-4 py-1 w-full bg-black text-white text-small'>
              {banner}
            </p>
          )}

          <div className='h-96 w-full overflow-y-auto'>
            {mode === 'view' ? (
              <p className='bg-white p-4 h-full w-full text-medium- whitespace-pre-wrap'>
                {data?.text}
              </p>
            ) : (
              <div className='relative h-96 w-full overflow-y-auto bg-white'>
                <textarea
                  className='p-4 pb-10 w-full'
                  placeholder='Write your page here'
                  rows={10}
                  value={text}
                  onChange={(e) => {
                    //@ts-ignore
                    setText(e.target.value);
                  }}
                />
                <p className='absolute bottom-2 right-2 text-small text-right text-gray'>
                  {/* @ts-ignore */}
                  Characters left: {1500 - text?.length}
                </p>
              </div>
            )}
          </div>

          <div className='pt-6' />
          <PageNav pageNumber={pageNumber} />
        </section>

        <section className='relative md:col-span-5 border-2 md:border-l-0 border-black overflow-hidden'>
          <header className='relative pb-8 pl-6 pt-8 '>
            <h1 className='flex items-baseline text-large--semibold'>
              Page - {pageNumber}
              <a
                className='ml-6 text-base font-normal block underline'
                href={etherscan}
                target='_blank'
                rel='noopener noreferrer'>
                View on Etherscan
              </a>
            </h1>
            <dl className='mt-5 space-y-5'>
              <DLItem
                desc='Versions'
                value={version}
                icon='/version-icon.svg'
              />
              {!!ownedBy ? (
                <DLItem
                  desc='Owner address'
                  value={owner}
                  icon='/user-icon.svg'
                  address={ownedBy}
                />
              ) : (
                <DLItem
                  desc='Owner address'
                  value={owner}
                  icon='/user-icon.svg'
                />
              )}
              <DLItem
                desc='Pages written'
                value={pagesWritten || 0}
                icon='/checkmark-icon.svg'
              />
            </dl>
          </header>

          {/* ACTION PANELS */}
          <div className='border-t-2 border-black'>
            {state === 'available' && (
              <BuyPageForm page={pageNumber} text={text} />
            )}
            {state === 'owned' && <PageOwnerForm page={data} text={text} />}
            {state === 'unavailable' && (
              <PageOwnedForm page={data} text={text} etherscan={etherscan} />
            )}
            {/* VIEWER FORM - CAN OFFER*/}
            {/* VIEWER FORM w AUCTION - CAN BID */}
            {/* OWNER FORM - CAN AUCTION */}
          </div>
        </section>
      </main>
      <div className='mt-8 flex items-center justify-center'>
        <img className='' src='/discord-icon.svg' alt='discord icon' />
        <a
          className=''
          href='https://discord.gg/Jvm6NaWtJu'
          target='_blank'
          rel='noopener noreferrer'>
          <span className='ml-4'>Join us on Discord</span>
        </a>
      </div>

      <div className='py-20' />
      <div className='mx-auto max-w-7xl'>
        {/* <h1 className='my-48 text-center'>BELOW HERE ARE WIP FORMS</h1>

        <OfferForm page={pageNumber} />
        <div className='py-20' />

        <CreateAuctionForm />
        <div className='py-20' />

        <AuctionBidForm page={pageNumber} />
        <div className='py-20' /> */}

        {/* TODO: see prior versions from firestore data */}
      </div>
    </div>
  );
};

const DLItem = ({ icon, desc, value, ...props }) => {
  if (props?.address) {
    return (
      <div className='flex'>
        <dt className='flex-shrink-0 flex items-center'>
          <img className='h-6 w-6' src={icon} alt='' />
          <span className='ml-4 text-medium'>{desc}</span>
        </dt>
        <a
          href={`https://ropsten.etherscan.io/address/${props?.address}`}
          target='_blank'
          rel='noopener noreferrer'>
          <dd className='ml-2 text-medium'>{value}</dd>
        </a>
      </div>
    );
  }
  return (
    <div className='flex'>
      <dt className='flex-shrink-0 flex items-center'>
        <img className='h-6 w-6' src={icon} alt='' />
        <span className='ml-4'>{desc}</span>
      </dt>
      <dd className='ml-2'>{value}</dd>
    </div>
  );
};

const PageNav = ({ pageNumber, ...props }) => {
  const history = useHistory();

  const back = pageNumber <= 1 ? 1 : pageNumber - 1;
  const forward = pageNumber >= BOOK_LENGTH ? BOOK_LENGTH : pageNumber + 1;

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center justify-around'>
        <Link to='/pages/1'>
          <ChevronDoubleLeftIcon className='h-6 md:h-10' />
        </Link>
        <Link to={`/pages/${back}`}>
          <ChevronLeftIcon className='h-6 md:h-10' />
        </Link>
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={{
          page: pageNumber,
        }}
        onSubmit={(values, actions) => {
          history.push(`/pages/${values.page}`);
        }}>
        {({ values, touched }) => {
          return (
            <Form>
              <div className='flex'>
                Page
                <Field className='mx-2 text-center border w-10' name='page' />
                of {BOOK_LENGTH}
              </div>
            </Form>
          );
        }}
      </Formik>

      <div className='flex items-center justify-around'>
        <Link to={`/pages/${forward}`}>
          <ChevronRightIcon className='h-6 md:h-10' />
        </Link>
        <Link to='/pages/100'>
          <ChevronDoubleRightIcon className='h-6 md:h-10' />
        </Link>
      </div>
    </div>
  );
};

import { collection, getDocs, query, where } from 'firebase/firestore';
import { QueryKey, useQuery } from 'react-query';
import { getCollection } from '../../utils/get_collection';
import { unpackSnapshotArray } from '../../utils/unpack_snapshot_array';
import FirebaseClient from '../firebase/firebase_client';

export const call = async ({ queryKey }: { queryKey: QueryKey }) => {
  const slug = getCollection();

  const snapshots = await getDocs(
    query(
      collection(FirebaseClient.db, `/contracts/${slug}/pages/`),
      where('minted', '==', false),
    ),
  );
  if (snapshots.empty) {
    return [];
  }
  const pages = unpackSnapshotArray({ snapshots })
    .sort((a, b) => a.id - b.id)
    .filter((page) => page.id > 1);
  // console.log('pages', pages[0]);
  return pages[0];
};

export const useNextAvailable = () => {
  return useQuery(['next-available', {}], call, {});
};

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AccountPage } from '../views/Account';
import { Admin } from '../views/Admin';
import { MyPages } from '../views/MyPages';
import { NextAvailable } from '../views/NextAvailable';
import { Page } from '../views/Page';
import { Pages } from '../views/Pages';
import { Versions } from '../views/Versions';
import { ErrorBoundary } from './ErrorBoundary';

export class Router extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== 'production') {
      console.group('ROUTER ERROR: COMPONENT DID CATCH');
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <React.Suspense fallback={<div>loading...</div>}>
          <Switch>
            <Route exact path='/' component={Pages} />
            <Route exact path='/book' component={Pages} />
            <Route exact path='/pages/:number' component={Page} />
            <Route exact path='/versions' component={Versions} />
            <Route exact path='/account' component={AccountPage} />
            <Route exact path='/my-pages' component={MyPages} />
            <Route exact path='/available' component={NextAvailable} />
            <Route exact path='/admin' component={Admin} />

            {/* <PrivateRoute></PrivateRoute> */}
            <Route path='/:slug'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </React.Suspense>
      </ErrorBoundary>
    );
  }
}

function PrivateRoute({ children, ...rest }: { children: any; rest?: any }) {
  // const { currentUser } = FirebaseClient.auth;
  const currentUser = ''; //TODO: grab metamask user
  // if (process.env.NODE_ENV !== "production") {
  //   console.log("Current user: ", currentUser);
  // }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

import MetaMaskOnboarding from '@metamask/onboarding';
import { ethers } from 'ethers';
import { createContext, useCallback, useContext } from 'react';
import { useEffect, useState } from 'react';

import FirebaseClient from '../api/firebase/firebase_client';

interface Value {
  accounts: string[];
  chainId: string;
  isConnected: boolean;
  publicAddress: string;
}

export const MetamaskContext = createContext<Value>({
  accounts: [],
  chainId: '',
  isConnected: false,
  publicAddress: '0x0',
});

/**
 *  Context for providing currently connected wallet and network information.
 *
 */
export const MetamaskProvider = ({ children }: { children: any }) => {
  const [accounts, setAccounts] = useState([]);
  const [chainId, setChainId] = useState('');

  const isConnected = !!accounts[0];

  const value = {
    accounts,
    chainId,
    isConnected,
    publicAddress: accounts[0],
  };

  const handleAccounts = useCallback(
    (newAccounts: any) => {
      try {
        // Make sure we have checksum addresses
        newAccounts = newAccounts.map(ethers.utils.getAddress);
        // console.log("newAccounts", newAccounts, "oldAccounts", accounts);

        // Only update when the account switched
        // TODO: need to make sure we don't show connected unless we have a valid Firebase Auth account
        if (newAccounts[0] !== accounts[0]) {
          setAccounts(newAccounts);
          // if we switch accounts and not just logged in, then sign out of Firebase
          if (typeof accounts[0] === 'string') {
            if (!!accounts) FirebaseClient.auth.signOut();
          }
        }
        // setAccounts([]);
      } catch (error) {
        console.error('useMetamaskAccount', error);
      }
    },
    [accounts],
  );

  const handleChainIdChange = useCallback((chainId: string) => {
    console.log('onChainIdChanged', chainId);
    setChainId(chainId);
    // TODO: should reload on chainId change
    // window.location.reload();
  }, []);

  /**
   * Accounts Listener
   */
  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.request({ method: 'eth_accounts' }).then(handleAccounts);
      window.ethereum.on('accountsChanged', handleAccounts);
      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccounts);
      };
    }
  }, [handleAccounts]);

  /**
   * Network Listener
   */
  useEffect(() => {
    try {
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then(handleChainIdChange);
      window.ethereum.on('chainChanged', handleChainIdChange);
    } catch (error) {
      console.error(error);
    }
  }, [handleChainIdChange]);

  return (
    <MetamaskContext.Provider value={value}>
      {children}
    </MetamaskContext.Provider>
  );
};

export const useMetamask = () => useContext(MetamaskContext);

export const AccountPage = () => {
  return (
    <div>
      <header>
        <h1>Your Account</h1>
        <h2>WIP - add avatar image</h2>
      </header>
    </div>
  );
};

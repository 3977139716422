import { collection, getDocs } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import FirebaseClient from '../api/firebase/firebase_client';
import { unpackSnapshotArray } from '../utils/unpack_snapshot_array';

export const Card = ({ page, ...props }) => {
  return (
    <Link
      className='relative m-3 p-6 flex flex-col justify-between bg-white text-left overflow-hidden transform hover:scale-110 ease-in-out hover:duration-700 border border-black'
      style={{ height: 255, width: 209 }}
      to={`/pages/${page.id}`}
      key={page.id}>
      <p className='text-small overflow-hidden'>{page.text}</p>
      <div className='flex justify-between'>
        <p className='text-medium'>{page.id}</p>
        <p className='text-small'>
          Status:{' '}
          <span className='text-medium-semibold capitalize'>
            {page?.status}
          </span>
        </p>
      </div>
    </Link>
  );
};

export const Pages = () => {
  const { data, isLoading } = useQuery(['pages'], async () => {
    const snapshots = await getDocs(
      collection(FirebaseClient.db, '/contracts/book-one-test/pages'),
    );

    return unpackSnapshotArray({ snapshots }).sort((a, b) => a.id - b.id);
  });

  console.log('data', data);
  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center'> Loading ...</div>
    );
  }
  return (
    <div>
      <section>
        <ul className='px-5 flex flex-wrap justify-center sm:justify-between'>
          {data?.map((page) => {
            return <Card key={page.id} page={page} />;
          })}
        </ul>
      </section>
    </div>
  );
};

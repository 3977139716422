import { Link } from 'react-router-dom';

import { UserMenu } from '../views/UserMenu';

export const MobileNavBar = () => {
  return (
    <nav className='md:hidden fixed top-2 px-8 z-30 w-full bg-not-gray'>
      <div className='border-2 border-black'>
        <div className='px-2 sm:px-6 lg:px-8'>
          <div className='relative flex justify-between h-16'>
            <div className='flex-1 flex itemscenter justifycenter items-stretch justify-start'>
              <div className='flex-shrink-0 flex items-center'>
                <Link to='/'>
                  <img className='block h-10 w-auto' src='/ludo.png' alt='' />
                </Link>
              </div>
            </div>
            <div className='self-center'>
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

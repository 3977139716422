import { ethers } from 'ethers';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { buyPage } from '../../api/metamask/buy_page';
import { useContract } from '../../contexts/contract.context';
import { useMetamask } from '../../contexts/metamask.context';
import { Spinner } from '../components/Spinner';

export const BuyPageForm = ({ page, text, ...props }) => {
  const { isConnected, publicAddress } = useMetamask();
  const { address: contractAddress } = useContract();

  const initialPrice = page === 1 || page === 100 ? 1 : 0;
  const formatedInitialPrice = initialPrice.toFixed(1);
  const price = ethers.utils.formatEther(initialPrice);

  const mutation = useMutation(
    async () => {
      if (!publicAddress) throw new Error('No metamask user found');

      const result = await buyPage({
        pageNumber: page,
        price,
        text,
        address: contractAddress,
      });
      // console.log('Buy Page Result', result);
      return { result };
    },
    {
      onSuccess: (data, action) => {},
    },
  );

  return (
    <div>
      <div className='border-gray-300 p-6'>
        <h1 className='py-4 text-xl font-semibold leading-160'>Price:</h1>
        <h2 className='text-3xl font-semibold leading-160'>
          {formatedInitialPrice} ETH
        </h2>

        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            if (isConnected) {
              mutation.mutate();
            } else {
              alert('Please connect to Metamask');
            }
          }}>
          {({ values }) => {
            return (
              <Form>
                <div className=''>
                  {mutation.isLoading ? (
                    <button
                      className='flex justify-center items-center w-full py-8 border-2 border-black bg-black text-white'
                      disabled
                      type='submit'>
                      <Spinner />
                      <p className='text-center'>
                        Buying... can take more than 10 minutes
                      </p>
                    </button>
                  ) : (
                    <button
                      className='flex justify-center items-center w-full py-8 border-2 border-black hover:bg-black  text-black hover:text-white'
                      type='submit'>
                      Buy now
                    </button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

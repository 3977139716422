import { httpsCallable } from 'firebase/functions';
import { useMutation } from 'react-query';
import FirebaseClient from '../api/firebase/firebase_client';
import { Spinner } from './components/Spinner';

export const Admin = () => {
  const seedDbMutation = useMutation(
    async (network: string) => {
      const response = await httpsCallable(
        FirebaseClient.functions,
        'seedDatabase',
      )({
        network,
      });
      return response;
    },
    {
      onSuccess: (response) => {
        console.log(response);
      },
    },
  );

  return (
    <div className='px-8'>
      <header>
        <h1>Admin Dashboard</h1>
      </header>

      <section className='my-10'>
        <header>
          <h2>Seed Testnet Database</h2>
        </header>
        <button
          className='bg-black text-white px-20 py-1'
          onClick={() => seedDbMutation.mutate('testnet')}>
          {seedDbMutation.isLoading && <Spinner />}
          Clear and seed
        </button>
      </section>

      <section className='my-10'>
        <header>
          <h2>Seed Mainnet Database</h2>
        </header>
        <button
          className='bg-black text-white px-20 py-1'
          onClick={() => seedDbMutation.mutate('mainnet')}>
          {seedDbMutation.isLoading && <Spinner />}
          Clear and seed
        </button>
      </section>
    </div>
  );
};

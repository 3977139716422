import { collection, getDocs, query, where } from 'firebase/firestore';
import { QueryKey, useQuery } from 'react-query';
import { getCollection } from '../../utils/get_collection';
import { unpackSnapshotArray } from '../../utils/unpack_snapshot_array';
import FirebaseClient from '../firebase/firebase_client';

export const call = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [, { address }] = queryKey as any[];

  const slug = getCollection();

  const snapshots = await getDocs(
    query(
      collection(FirebaseClient.db, `/contracts/${slug}/pages/`),
      where('ownedBy', '==', address),
    ),
  );
  return unpackSnapshotArray({ snapshots }).sort((a, b) => a.id - b.id);
};

export const useMyPages = ({ address }: { address: string | undefined }) => {
  return useQuery(['my-pages', { address }], call, {
    enabled: !!address,
  });
};
